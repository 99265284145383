import isBrowser from 'lib/isBrowser';
import * as regexp from 'lib/regexp';

export const replaceQuotes = (value: string | undefined) =>
  value?.replaceAll('\'', '"');

// const envTemp = {
//   NEXT_PUBLIC_GIT_TAG: 'v1.21.0',
//   NEXT_PUBLIC_HOMEPAGE_CHARTS: '[daily_txs]',
//   NEXT_PUBLIC_API_HOST: 'bs-testnet.gelios.io',
//   NEXT_PUBLIC_API_WEBSOCKET_PROTOCOL: 'wss',
//   NEXT_PUBLIC_NETWORK_ID: '5',
//   NEXT_PUBLIC_NETWORK_CURRENCY_SYMBOL: 'gBTC',
//   NEXT_PUBLIC_GIT_COMMIT_SHA: '47566b89',
//   NEXT_PUBLIC_VISUALIZE_API_HOST: 'https://bs-vis-testnet.gelios.io',
//   NEXT_PUBLIC_APP_HOST: 'bs-testnet.gelios.io',
//   NEXT_PUBLIC_APP_INSTANCE: 'pw',
//   NEXT_PUBLIC_NETWORK_CURRENCY_DECIMALS: '18',
//   NEXT_PUBLIC_NETWORK_NAME: 'Gelios chain',
//   NEXT_PUBLIC_NETWORK_SHORT_NAME: 'Gelios chain',
//   NEXT_PUBLIC_API_PROTOCOL: 'https',
//   NEXT_PUBLIC_IS_TESTNET: 'false',
//   NEXT_PUBLIC_API_BASE_PATH: '/',
//   NEXT_PUBLIC_APP_PROTOCOL: 'https',
//   NEXT_PUBLIC_API_SPEC_URL:
//     'https://raw.githubusercontent.com/blockscout/blockscout-api-v2-swagger/main/swagger.yaml',
//   NEXT_PUBLIC_STATS_API_HOST: 'https://bs-stats-testnet.gelios.io',
//   NEXT_PUBLIC_NETWORK_CURRENCY_NAME: 'gBTC',
// };
export const getEnvValue = (envName: string) => {
  // eslint-disable-next-line no-restricted-properties
  const envs = isBrowser() ? window.__envs : process.env;
  // const envs = envTemp;

  if (isBrowser() && envs.NEXT_PUBLIC_APP_INSTANCE === 'pw') {
    const storageValue = localStorage.getItem(envName);

    if (typeof storageValue === 'string') {
      return storageValue;
    }
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return replaceQuotes(envs[envName]);
};

export const parseEnvJson = <DataType>(
  env: string | undefined,
): DataType | null => {
  try {
    return JSON.parse(env || 'null') as DataType | null;
  } catch (error) {
    return null;
  }
};

export const getExternalAssetFilePath = (envName: string) => {
  const parsedValue = getEnvValue(envName);

  if (!parsedValue) {
    return;
  }

  return buildExternalAssetFilePath(envName, parsedValue);
};

export const buildExternalAssetFilePath = (name: string, value: string) => {
  try {
    const fileName = name
      .replace(/^NEXT_PUBLIC_/, '')
      .replace(/_URL$/, '')
      .toLowerCase();
    const url = new URL(value);
    const fileExtension = url.pathname.match(regexp.FILE_EXTENSION)?.[1];
    return `/assets/${ fileName }.${ fileExtension }`;
  } catch (error) {
    return;
  }
};
