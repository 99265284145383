import { chakra } from '@chakra-ui/react';

const TextAd = ({ className }: {className?: string}) => {
  return <div className={ className }></div>;
  // const hasAdblockCookie = cookies.get(cookies.NAMES.ADBLOCK_DETECTED, useAppContext().cookies);

  // if (!config.features.adsText.isEnabled || hasAdblockCookie) {
  //   return null;
  // }

  // return <CoinzillaTextAd className={ className }/>;
};

export default chakra(TextAd);
